  import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GlobalService } from 'src/services/global.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('closeModal') closebutton:any;


  active = 'top';
  account: any;
  isConnected: boolean = false;
  playerdetails: any;
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
  sidebarExpanded = true;

  constructor(private cs: GlobalService) { }

  ngOnInit(): void {
    this.getaccount();
  }

  async getaccount() {
    try {
      this.cs.init();

      this.cs.getWalletObs().subscribe((data: any) => {
        if (this.cs.isValidAddress(data)) {
          this.closebutton.nativeElement.click();
          this.account = data;
          this.isConnected = true;
          let that = this;
        } else {
          this.isConnected = false;
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  connect(walletType:any)
  {
    if(walletType==1){
    this.cs.connectContract();
    }
    else
    {
      this.cs.connectAccountWalletConnect();
    }
  }
}

