
<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
    <div class="header">
      
      <div class="toggle" (click)="handleSidebarToggle()">
        <i class="fa-solid fa-sliders"></i>
      </div>
    </div>
    <div class="content">
      <div class="link">
        <div class="content" [routerLink]="['/home']" [routerLinkActive]="'active'">
          
          <div class="title">Home</div>
        </div>
      </div>
      <div class="link with-children">
        <div class="content" [routerLink]="['/configuration']" [routerLinkActive]="'active'">
         
          <div class="title">Configuration</div>
        </div>
        <div class="children">
          <div class="link">
            <div class="content" [routerLink]="['/configuration']" [queryParams]="{ 'child': '1' }"
              [routerLinkActive]="'active'">
             
              <div class="title">child 1</div>
            </div>
          </div>
          <div class="link">
            <div class="content" [routerLink]="['/configuration']" [queryParams]="{ 'child': '2' }"
              [routerLinkActive]="'active'">
              
              <div class="title">child 2</div>
            </div>
          </div>
          <div class="link">
            <div class="content" [routerLink]="['/configuration']" [queryParams]="{ 'child': '3' }"
              [routerLinkActive]="'active'">
              
              <div class="title">child 3</div>
            </div>
          </div>
        </div>
      </div>
      <div class="link">
        <div class="content" [routerLink]="['/bookings']" [routerLinkActive]="'active'">
          <div class="icon">
           
          </div>
          <div class="title">Bookings</div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>