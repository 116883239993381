<nav class="navbar navbar-expand-lg navbar-light" >
    <div class="container">
      
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: space-between;" >
        <ul class="navbar-nav mb-2 mb-lg-0">
         
          
        <li><a class="navbar-brand" href="#"><img src="assets/images/logo.png" alt="" width="51px" ></a></li>
         
        </ul>
        
        <form class="d-flex">
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="media-icon" > <a href=""><i class="fa-brands fa-telegram"></i></a> </li>
            <li class="media-icon"> <a href=""><a class="navbar-brand m-auto" href="#"><img src="assets/images/logo.png" alt="" width="30px" ></a></a> </li>
            <li class="media-icon"> <a href=""><i class="fa-brands fa-square-youtube"></i></a> </li>
            <li class="media-icon">
              <a *ngIf="isConnected" class="connectbutton">
                {{ account.substring(0, 4) }}...{{ account.substring(38, 42) }}
              </a>
                <a *ngIf="!isConnected" class="connectbutton" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Connect Wallet
                </a>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </nav>

  <!-- <app-sidebar [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded" >
    <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }">
     
        
      
     
      
    </div>
  </app-sidebar> -->

  <section>
    <div class="container">
        <!-- Button trigger modal -->

  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="position: relative;" >
      <div class="modal-content">
        <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close">
           X
        </button>
        <div class="modal-header justify-content-center border-0">
          
          <h5 class="modal-title " id="staticBackdropLabel">CONNECT WALLET</h5>
         
        </div>
        <div class="modal-body">
           
            
            <div class="d-flex px-4" >
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" style="width: 50%;justify-content: center;" >
                    <li ngbNavItem="top"  >
                        <a href="javascript:void(0)" (click)="connect(1)" ngbNavLink>Meta Mask</a>
                        <ng-template ngbNavContent>
                          <a href=""> 
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png" alt="" width="100px" >
                            
                       <p  style="font-size: 14px;
                       font-weight: 600;">OPENING METAMASK</p></a>
                        </ng-template>
                    </li>
                    <li ngbNavItem="middle">
                        <a  href="javascript:void(0)" (click)="connect(2)" ngbNavLink>WalletConnect</a>
                        <ng-template ngbNavContent>
                          <a href="">
                            <img src="https://time.com/img/icons/wallet-connect.png" alt="" width="100px">
                            <p style="font-size: 14px;
                            font-weight: 600;" >OPENING WALLETCONNECT</p>
                          </a>
                        </ng-template>
                    </li>
                   
                </ul>
            
                <div [ngbNavOutlet]="nav" class="ms-4" style="width: 50%;
                text-align: center;" ></div>
            </div>
            <p class="text-center" style="position: absolute;
            bottom: 12px;
            width: 93%;" >Got questions?

               <span class="connectwallet" ><a href=""> Contact support</a></span></p>
            
            
        </div>
        
      </div>
    </div>
  </div>
    </div>
</section>