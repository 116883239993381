import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/services/global.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  active = 'top';

  hidestar: boolean = false;
  contractaddress: any;
  url_id: string = '';
  account: any;
  isConnected: boolean = false;
  playerdetails: any;
  availableToWithdraw: any;
  userbalance: any;
  contractInfo: any;
  base_url = location.origin;
  roiPercentage: any;
  amountChange:any=0;
  contractBalance: any;


  constructor(
    private cs: GlobalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    localStorage.setItem('type', "2");
    this.contractaddress = this.cs.roiContractAddress;
    setTimeout(() => {
      this.hidestar = false;
    }, 5000);
    this.getaccount();
    let that: any = this;
    window.addEventListener(
      'message',
      function (e: any) {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (that.account != e.data.message.data.address) {
            that.account = e.data.message.data.address;
            that.getaccount();
            that.emptyamount = false;
            that.erroroccured = false;
            that.erroroccuredaccount = false;
          }
        }
      }.bind(this)
    );

    this.url_id = this.cs.getUplineid(this.route);
  }

  async getaccount() {
    try {
      this.cs.init();

      this.cs.getWalletObs().subscribe((data: any) => {
        if (this.cs.isValidAddress(data)) {
          this.account = data;
          this.isConnected = true;
          let that = this;
          setInterval(function () {
            that.loadData();
          }, 5000);

          this.loadData();
        } else {
          this.isConnected = false;
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  setAmount(amt:any)
  {
    this.amountChange = amt*this.roiPercentage/10000;
  }

  
  async loadData() {
    this.isConnected = true;
    // this.commonPlayerDetails = await this.cs.commonContract._userInfo(this.account);
     this.playerdetails = await this.cs.UserInfo();

    console.log('********************************');
    console.log(parseInt(this.playerdetails.totalInvested, 16));
    
     this.contractInfo = await this.cs.contractInfo();
    this.userbalance =await this.cs.getBalance(this.account);
    this.roiPercentage = await this.cs.stakingContract.getRoiPercentage();
      this.contractBalance = await this.cs.getBalance(this.cs.roiContractAddress);
  }

  async deposit(amount: any) {
    try {
      let amountInEth = ethers.utils.parseEther(amount);
     
            await this.cs.deposit(this.url_id, amountInEth);
     
    } catch (e : any) {
      this.toastr.error(e.data.message);
    }
  }

  async withdraw() {
    try {
      await this.cs.withdraw();
    } catch (e:any) {
      this.toastr.error(e.data.message);
    }
  }

  async setContractType(type: number) {
    localStorage.setItem('type', "2");
    location.reload();
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Copied!');
  }
}
