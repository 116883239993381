import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ethers } from "ethers";
import { Observable, BehaviorSubject } from 'rxjs';
import WalletConnectProvider from "@walletconnect/web3-provider";


function _window(): any {
  return window;
}



declare let require: any;
declare let window: any;
const stakingAbi = require('./../assets/abi.json');
const providerOptions = {
  rpc: {
    56: "https://bsc-dataseed1.binance.org",
  },
  network: "binance",
  chainId: 56,
};

const provider = new WalletConnectProvider(providerOptions);

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public roiContractAddress: string = "0xe245dB60Aaec13DCCaBf9BfeA667eef8E206dBc3";
  public owner_address: string = "0x0000000000000000000000000000000000000000";
  public _web3: any;
  stakingContract: any;
  _account: any;
  counter = 1;
  http!: HttpClient;
  provider: any;
  signer: any;

  public walletDetails$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  commonContract!: ethers.Contract;

  constructor() {

  }

  getWalletObs(): Observable<any> {
    return this.walletDetails$.asObservable();
  }

  setWalletObs(profile: any) {
    this.walletDetails$.next(profile);
  }


  init(): void {
    let isConnected = localStorage.getItem('wallet') == "1";
    if (isConnected) {
      if ((localStorage.getItem('wallet') ?? "1") == "1") {
        this.connectContract();
      }
      else if ((localStorage.getItem('wallet') ?? "1") == "2") {
        this.connectAccountWalletConnect();
      }
    }
  }

  async connectContract() {
    // var web3 = new Web3( 'https://bsc-dataseed1.binance.org:443/');
    // this.commonContract =new web3.eth.Contract(tokenAbi,this.address);

    if ((typeof this.nativeWindow.ethereum) !== undefined && (typeof this.nativeWindow.ethereum) != undefined && (typeof this.nativeWindow.ethereum) != 'undefined') {
      await this.nativeWindow.ethereum.enable();
      this.provider = new ethers.providers.Web3Provider(this.nativeWindow.ethereum);

      await this.getAccountAddress();
      localStorage.setItem('wallet', '1');

      this.nativeWindow.ethereum.on("accountsChanged", (accounts: string[]) => {
        this.connectContract();
        location.reload();
      });

      this.nativeWindow.ethereum.on("networkChanged", (code: number, reason: string) => {
        this.connectContract();
        location.reload();
      });
    }
  }

  async connectAccountWalletConnect() {
    await provider.enable().then(() => console.log("first call resolved"))
      .catch(() => provider.disconnect());;

    this.provider = new ethers.providers.Web3Provider(provider);
    await this.getAccountAddress();
    localStorage.setItem('wallet', '2');

    provider.on("accountsChanged", async (accounts: string[]) => {
      location.reload();
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code: number, reason: string) => {
      location.reload();
    });

    // Subscribe to session disconnection
    provider.on("networkChanged", (code: number, reason: string) => {
      this.connectAccountWalletConnect();
    });
  }


  async getAccountAddress() {
    this.signer = this.provider.getSigner();
    this._account = await this.signer.getAddress();
    var network = await this.provider.getNetwork();
    localStorage.setItem('address', this._account);
    if (network.chainId == 80001) {
      this.stakingContract = new ethers.Contract(this.roiContractAddress, stakingAbi, this.signer);
    }
    this.setWalletObs(this._account);
  }

  get nativeWindow(): any {
    return _window();
  }

  async getAccount() {
    return this._account;
  }

  public async getUserBalance() {
    let account = await this.getAccount();
    return account;
  }

  public isValidAddress(address: any) {
    return ethers.utils.isAddress(address);
  }

  getUplineid(route: any): string {
    let url_id = this.owner_address;
    if (route.snapshot.url.length > 1)
      url_id = route.snapshot.url[1].path;


    return url_id;

  }

  async getBalance(address:any)
  {
    return await this.provider.getBalance(address);

  }




  public async UserInfo(): Promise<any> {
    return await this.stakingContract.userInfo(this._account);
  }

  public async contractInfo(): Promise<any> {
    return await this.stakingContract.contractInfo();
  }

  public async userInfoBankC(userAddress: any): Promise<any> {
    return await this.stakingContract.userInfoBankC(userAddress);
  }

  public async getUserPercentRate(userAddress: any): Promise<any> {
    return await this.stakingContract.getUserPercentRate(userAddress);
  }


  public async getUserDividends(userAddress: any): Promise<any> {
    return await this.stakingContract.getUserDividends(userAddress);
  }

  weitoether(amount: any) {
    return amount / 1000000000000000000;
  }

  public async withdraw(): Promise<any> {
    await this.stakingContract.withdraw();
  }

  public async deposit(upline: any, amount: any) {
    debugger;
    await this.stakingContract.deposit(upline, { value: amount });
  }

}
