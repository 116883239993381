<section style="    background: #333;
padding: 1rem;" >
  <div class="container">
    <ul style="display: flex;     justify-content: space-between;
    color: white;
    list-style: none; margin: 0;" >
        <li>
            
            <a href="">Home</a>
        </li>
        <li>  <a href="">Calculator</a></li>
        <li>  <a href="">CAT Bonus</a></li>
        <li>  <a href="">Our mission</a></li>
        <li>  <a href="">Support</a></li>
    </ul>
    <hr style="color:white" >
    <p style="font: 18px; color: white; margin: 15px; text-align: center;" >© 2022 LYNX. ALL RIGHTS RESERVED</p>
  </div>
</section>
