<section class="main">
    
    <div class="container">
        <h1 class="title">Crypto Arbitrage Team</h1>
        <p>
            — a decentralized platform of the largest crypto arbitrage team Build your
            wealth with crypto arbitrage — start now with the leaders of the market
        </p>

        <ul class="d-flex" style="justify-content: space-between;">
            <li>0.1 BNB minimum investment</li>
            <li>Daily withdrawals</li>
            <li>Up to 2.7% daily ROI</li>
        </ul>
        <div>
            <button class="btn btn-primary registerbutton">Register</button>
        </div>
    </div>
</section>

<section class="secondmain">
    <div class="container">
        <h2 class="title price">500,000,000,000</h2>
        <p>Volume of crypto arbitrage market</p>
        <div class="how">
            <h3 class="title">How does CAT work?</h3>

            <div class="row">
                <div class="col-md-3">
                    <h4 class="title">Step 1</h4>
                    <img src="assets/images/banner.png" alt="" style="    width: 50%;" />
                    <p class="howpara">
                        You provide CAT liquidity for arbitrage transactions through the smart
                        contract
                    </p>
                </div>
                <div class="col-md-3">
                    <h4 class="title">Step 2</h4>
                    <img src="assets/images/banner.png" alt="" style="    width: 50%;" />
                    <p class="howpara">
                        You provide CAT liquidity for arbitrage transactions through the smart
                        contract
                    </p>
                </div>
                <div class="col-md-3">
                    <h4 class="title">Step 3</h4>
                    <img src="assets/images/banner.png" alt="" style="    width: 50%;" />
                    <p class="howpara">
                        You provide CAT liquidity for arbitrage transactions through the smart
                        contract
                    </p>
                </div>
                <div class="col-md-3">
                    <h4 class="title">Step 4</h4>
                    <img src="assets/images/banner.png" alt="" style="    width: 50%;" />
                    <p class="howpara">
                        You provide CAT liquidity for arbitrage transactions through the smart
                        contract
                    </p>
                </div>
            </div>
            <div>
                <button class="btn btn-primary my-4">Provid Liquidity</button>
            </div>
        </div>
    </div>
</section>
<section class="arbitage">
    <div class="container">
        <h4 class="title">What is arbitrage?</h4>

        <div class="row">
            <div class="col-md-8 ">
                <p>
                    Cryptocurrency arbitrage is a trading strategy that uses price
                    differences between identical cryptocurrency assets in different
                    markets or different pairs of cryptocurrencies in the same market to
                    generate profits with relatively low risk.
                </p>
                <p>
                    Today, it is the only industry that stably and legally allows you to
                    get from 8 to 10% profit daily or x2 of the investment amount in 2
                    weeks.
                </p>
            </div>
            <div class="col-md-4 text-center">
                <img src="assets/images/banner.png" alt="" />
            </div>
        </div>
    </div>
</section>
<section class="joinsection">
    <div class="container">
        <h4 class="title">Why should I join CAT?</h4>
        <div class="row justify-content-center ">
            <div class="col-md-10">
                <div class="card">
                    <div class="row">
                        <div class="col-md-4">
                            <h5 class="title">985.5% yearly ROI</h5>
                        </div>
                        <div class="col-md-8">
                            <p>
                                Thanks to our unique trading strategy, CAT generates high returns
                                in the shortest possible time in arbitrage bundles, that result in
                                a high return % for the client.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h5 class="title">Easy start</h5>
                    </div>
                    <div class="col-md-8">
                        <p>
                            CAT’s arbitrage systems will automatically put your money to work,
                            all you need to do is to deposit your funds and relax!
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h5 class="title">Money</h5>
                    </div>
                    <div class="col-md-8">
                        <p>
                            The CAT community generates high liquidity, that allows you to
                            start with a minimum amount and increase it later on.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h5 class="title">Speed</h5>
                    </div>
                    <div class="col-md-8">
                        <p>
                            The automated system guarantees fast rewards, transactions and
                            instant withdrawal.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h5 class="title">CAT Bonus</h5>
                    </div>
                    <div class="col-md-8">
                        <p>
                            Invite your friends/partners to your CAT team and get instant
                            rewards.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <h5 class="title">Profit calculator</h5>
        <p>Calculate how much you can earn with CAT</p>

        <div class="row">
            <div class="col-md-4">
                <h6>Amount of investment</h6>
                <p>650 BNB</p>
                <div>
                    <label for="customRange1" class="form-label">Example range</label>
                    <input type="range" class="form-range" id="customRange1" />
                </div>
            </div>
            <div class="col-md-4">
                <h6>Amount of investment</h6>
                <p>650 BNB</p>
                <div>
                    <label for="customRange1" class="form-label">Example range</label>
                    <input type="range" class="form-range" id="customRange1" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                Profit
                            </p>
                            <h6>
                                + 168.21 BNB
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <p>
                                Period
                            </p>
                            <h6>
                                7 days
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <p>
                                Profit percentage
                            </p>
                            <h6>
                                18.90%
                            </h6>
                        </div>
                        <div class="col-md-6">
                            <p>
                                Total balance
                            </p>
                            <h6>
                                1058.21 BNB
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div>
                    <button class="btn btn-primary">Start Investing</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <h4 class="title">
            How can I join CAT?
        </h4>

        <div class="row">
            <div class="col-md-6">
                <img src="assets/images/banner.png" alt="" />
            </div>
            <div class="col-md-6">
                <ul>
                    <li>
                        1.

                        Join the platform by connecting your wallet (Wallet Connect or MetaMask).
                    </li>
                    <li>
                        2.

                        Choose how much you’d like to deposit.
                    </li>
                    <li>
                        3.

                        Sit back and watch the profits rain down!


                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div>
                    <button class="btn btn-primary">Start Now !</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <h4 class="title">
            Our Mission
        </h4>
        <p>
            "Our mission is to give absolutely everyone the opportunity to fulfill their dreams and follow them, without
            risk.
            The most valuable and quickly exhaustible resource in life is time.
            Using modified arbitrage bundles and trading strategies, CAT generates the highest earnings in the shortest
            possible time, which gives all participants the opportunity to make money in the arbitrage window, avoiding
            risks and time spent on learning the trade."
        </p>
        <p>— CAT Team</p>
        <img src="assets/images/banner.png" alt="">
    </div>
</section>
<section>
    <div class="container">
        <h4 class="title">
            FAQ
        </h4>
        <div class="row">
            <div class="col-md-10">
                <div>
                    <p>
                        <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            What is crypto arbitrage?
                        </a>

                    </p>
                    <div class="collapse" id="collapseExample">
                        <div class="card card-body">
                            Cryptocurrency arbitrage is a trading strategy that uses price differences between identical
                            cryptocurrency assets in different markets or different pairs of cryptocurrencies in the
                            same
                            market to generate profits with relatively low risk. Today, it is the only industry that
                            stably
                            and legally allows you to get from 7 to 9% profit daily or x2 of the investment amount in 2
                            weeks.
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        <a data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            What kind of income can crypto arbitrage bring?
                        </a>

                    </p>
                    <div class="collapse" id="collapseExample1">
                        <div class="card card-body">
                            Today this is the only industry that allows you to receive from 8% to 10% of daily profit,
                            reliably and legally.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <h4 class="title">
            Still have any questions?
        </h4>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <div>
                            <button class="btn btn-primary">Telegram</button>
                        </div>
                        <div>
                            <button class="btn btn-primary">support@mlynx.team</button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="assets/images/mainicon.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>